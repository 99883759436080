import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion'
import { BsChevronDown, BsChevronRight, BsChevronUp, BsFillEyeFill, BsFillEyeSlashFill, BsX } from "react-icons/bs";
import Layout from "../../components/Layout";
import { callAPI } from "../../helper/apiUtils";
import { Link, useHistory, withRouter } from "react-router-dom";
import { $user } from "../../helper/UserFactory";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { $crud } from "../../helper/CrudFactory";
import { LANGUAGE, RESET_SOCKET, SET_AUTO_TRANSLATION, SET_USER, SET_USER_LOGIN_TOKEN } from "../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import { version } from '../../../package.json';
import { KJUUP_COLORS } from '../../helper/constants';

function ProfileSetting(props) {
    const history = useHistory();
    const [isAutomatic, setIsAutomatic] = useState(false)
    const [viewPassword, setViewPassword] = useState(false);
    const [viewPasswordConfirm, setViewPasswordConfirm] = useState(false);

    const dispatch = useDispatch();
    const [display, setDisplay] = useState(null)
    const [displayU, setDisplayU] = useState(null)
    const [viewLoader, setViewLoader] = useState(false)
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({

    });
    const [passwordParam, setPasswordParam] = useState({
        "password": "",
        "confirmPassword": ""
    })
    const user = $user.get();
    const userN = useSelector((state) => state.user);
    const [username, setusername] = useState(user?.username)
    const resetEmail = async () => {
        const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if (reg.test(email) == false) {
            await $crud.notify({
                type: 'error',
                message: 'Invalid Email Address'
            })
        } else {
            const { data } = await callAPI("/user/resetEmail", {}, 'PUT', { email: email })
            setDisplay('')
            if (!data.status) {
                await $crud.notify({
                    type: 'error',
                    message: data.message
                })
                history.push('/profile-setting')
            } else {
                if (user.username) {
                    await $crud.confirm({
                        title: "",
                        textContent: data.message,
                        options: {
                            ok: "Ok",
                            cancel: ""
                        },
                    })
                    setDisplay(null)
                }
                else {
                    await $crud.confirm({
                        title: "",
                        textContent: data.message,
                        options: {
                            ok: "Ok",
                            cancel: ""
                        },
                    })
                    setDisplay(null)
                    $user.logout();
                    history.push('/')
                }
            }
        }
    }
    const logout = async () => {
        localStorage.removeItem("autoTrans")
        localStorage.removeItem("videoCallPop");
        const { data } = await callAPI('/user/logout', {}, 'GET')
        dispatch({ type: RESET_SOCKET, payload: true })
        await $user.logout();
        dispatch({ type: SET_AUTO_TRANSLATION, status: false })
        dispatch({ type: SET_USER_LOGIN_TOKEN, status: null })
        history.push('/')
    }

    const resetPassword = async () => {
        const { password, confirmPassword } = passwordParam
        if (!password) {
            await $crud.notify({
                type: 'error',
                message: "Please enter password"
            })
            return false;
        }
        if (password !== confirmPassword) {
            await $crud.notify({
                type: 'error',
                message: isGerm ? DE.pp_not_match : EN.pp_not_match
            })
        } else {
            const passwordRegex = /(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+|}{:"?><,./;'\[\]\\\-]).{12,}/;
            if (!passwordParam.password || !passwordRegex.test(passwordParam.password)) {
                await $crud.notify({
                    type: 'error',
                    message: isGerm ? DE.pass_error : EN.pass_error
                })
            }
            else {
                if (user.username) {
                    history.push({ pathname: '/security-pin-main', state: { isResetPassword: true, password: password, confirmPassword: confirmPassword } })
                }
                else {
                    const { data } = await callAPI("/user/resetPassword", {}, 'post', passwordParam)
                    if (!data.status) {
                        await $crud.notify({
                            type: 'error',
                            message: data.message
                        })
                        history.push('/profile-setting')
                    } else {
                        await $crud.confirm({
                            title: "",
                            textContent: data.message,
                            options: {
                                ok: "Ok",
                                cancel: ""
                            },
                        })
                        $user.logout();
                        history.push('/')
                    }
                }
            }
        }
    }

    const resetUserName = async () => {
        if (!username) {
            await $crud.notify({
                type: 'error',
                message: "Please enter username"
            })
            return false;
        }
        else if (username == user.username) {
            await $crud.notify({
                type: 'error',
                message: "Username could't be same as before"
            })
            return false;
        }
        else {
            history.push({ pathname: '/security-pin-main', state: { isResetUsername: true, username: username } })
        }
    }

    useEffect(() => {
        _checkAndSet()
    }, [])

    const _checkAndSet = async () => {
        let userLang = await localStorage.getItem('autoTrans');
        if (userLang) {
            setIsAutomatic(true)
        }
    }

    const { isGerm, setIsGerm } = React.useContext(LanguageContext);
    const [language, setLanguage] = React.useState(isGerm ? 'DE' : "EN");
    const handleChangeLanguage = async (e) => {
        let lang = e.target.value
        const { data } = await callAPI(`/user/language?lang=${lang?.toLowerCase()}`, {}, 'PUT')
        if (data?.status) {
            let getProfVal = localStorage.getItem("user");
            let filterVal = JSON.parse(getProfVal);
            let language = lang
            filterVal = { ...filterVal, language: language?.toLowerCase() }
            localStorage.setItem("user", JSON.stringify(filterVal))
        }
        lang === "DE" ? localStorage.setItem('switch', '2') : localStorage.setItem('switch', '1');
        setIsGerm(!isGerm);
        if (isGerm) {
            setLanguage('EN')
        } else {
            setLanguage('DE')
        }

    }
    const handleTranslateLng = async (e) => {
        if (!props.language) {
            await $crud.confirm({
                title: "",
                textContent: isGerm ? DE.active_trans : EN.active_trans,
                options: {
                    ok: isGerm ? DE.confirm : EN.confirm,
                    cancel: isGerm ? DE.cancel : EN.cancel
                }
            });
        }
        if (props.language) {
            await $crud.confirm({
                title: "",
                textContent: isGerm ? DE.disable_trans : EN.disable_trans,
                options: {
                    ok: isGerm ? DE.confirm : EN.confirm,
                    cancel: isGerm ? DE.cancel : EN.cancel
                }
            });
            props.toggleLanguage(null)
            dispatch({ type: SET_AUTO_TRANSLATION, status: false })
            localStorage.removeItem("autoTrans")
            setIsAutomatic(false)
        } else {
            let lang = navigator.language;
            lang = lang?.split("-");
            props.toggleLanguage(lang[0])
            dispatch({ type: SET_AUTO_TRANSLATION, status: true })
            localStorage.setItem("autoTrans", 'true')
            setIsAutomatic(true)
        }
    }

    const handleClass = (index) => {
        if (display === index) {
            setDisplay(null)
        } else {
            setDisplay(index)

        }
    }

    const handleClassX = (index) => {
        if (displayU === index) {
            setDisplayU(null)
        } else {
            setDisplayU(index)

        }
    }

    const setPasswordParams = async (key, value) => {
        setPasswordParam(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
    }


    const deleteProfile = async () => {
        await $crud.confirm({
            title: isGerm ? DE.delete_profile : "Are you sure?",
            textContent: isGerm ? (user.username ? DE.DeleteKjuupPlusText : DE.delete_profile_text) : (user.username ? EN.DeleteKjuupPlusText : EN.delete_profile_text),
            options: {
                ok: isGerm ? DE.confirm : EN.confirm,
                cancel: isGerm ? DE.cancel : EN.cancel
            }
        });
        setViewLoader(true)
        const data = await callAPI(`/user/deactivate`, {}, "GET");
        if (data) {
            $crud.notify({
                type: 'success',
                message: "Profile Deleted Successfully"
            })
        }

        await $user.logout();
        localStorage.removeItem("autoTrans")
        dispatch({ type: SET_AUTO_TRANSLATION, status: false })
        setViewLoader(false)
        history.push("/");
        await $crud.notify({
            type: 'warning',
            message: "This functionality is temporary disabled",
        })
    }

    const [isEmailOn, setIsEmailOn] = useState(userN?.emailNotification && userN?.emailVerified)

    const _onPreEmailNotification = async () => {
        let emailNotification = !isEmailOn
        dispatch({ type: SET_USER, user: { ...userN, emailNotification: emailNotification } })
        setIsEmailOn(emailNotification)
        await callAPI(`/user/emailNotification`, {}, "PUT", {
            emailNotification
        });
    }

    const cardRef = useRef(null);

    return (
        <React.Fragment>
            <Layout>
                <div className="company_section w-300 company_list setting_profile">
                    {viewLoader && (
                        <div className="website_loader">
                            <div className="loader" />
                        </div>
                    )}
                    <div className="company_header">
                        <div className="heading_icon">
                            <Link to="/profile" className="main_heading">
                                <img width={10}
                                    src={process.env.PUBLIC_URL + "/assets/img/back.svg"} />{isGerm ? DE.settings : EN.settings}
                            </Link>
                        </div>
                    </div>
                    <div className="setting_accrd">
                        <Accordion defaultActiveKey="0">
                            <Card className="langu">
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="3"
                                    onClick={(e) => handleClass('3')}>{isGerm ? DE.language : EN.language}
                                    {
                                        (display === '3') &&
                                        <BsChevronUp className="acr_up" />
                                    }
                                    {
                                        (display !== '3') &&
                                        <BsChevronDown className="acr_down" />
                                    }</Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <Form.Group>
                                            <Form.Control value={language} onChange={(e) => {
                                                handleChangeLanguage(e)
                                            }} as="select">
                                                <option value="EN">{isGerm ? DE.english : EN.english}</option>
                                                <option value="DE">{isGerm ? DE.german : EN.german}</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    {isGerm ? DE.auto_chat_translation : EN.auto_chat_translation}
                                    <Form className="project-checkbox"><Form.Check checked={isAutomatic} onChange={handleTranslateLng}
                                        type="switch" id="custom-switch-a" /></Form>
                                </Card.Header>
                            </Card>
                            {/* <Card>
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="0"
                                    onClick={e => handleClass('0')}>{isGerm ? DE.dashboard : EN.dashboard}
                                    {
                                        (display === '0') &&
                                        <BsChevronUp className="acr_up" />
                                    }
                                    {
                                        (display !== '0') &&
                                        <BsChevronDown className="acr_down" />
                                    }
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className="dashboard_short_info">
                                            <div>0 <span>{isGerm ? DE.profile : EN.profile}-
                                                <br /> {isGerm ? DE.views : EN.views}</span></div>
                                            <div>1 <span>{isGerm ? DE.article : EN.article}-
                                                <br /> {isGerm ? DE.views : EN.views}</span></div>
                                            <div>2 <span>{isGerm ? DE.times_seen : EN.times_seen}
                                                <br /> {isGerm ? DE.in_search : EN.in_search}</span></div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> */}
                            <Card>
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="1"
                                    onClick={e => handleClass('1')}
                                >{isGerm ? DE.reset_password : EN.reset_password}
                                    {
                                        (display === '1') &&
                                        <BsChevronUp className="acr_up" />
                                    }
                                    {
                                        (display !== '1') &&
                                        <BsChevronDown className="acr_down" />
                                    }
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <Form className="add_company_from">
                                            <Form.Group controlId="formBasicEmail">
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                                    <Form.Control
                                                        autoComplete="off"
                                                        type={viewPassword ? "text" : "password"}
                                                        value={passwordParam.password}
                                                        onChange={(e) => {
                                                            setPasswordParams('password', e.target.value)
                                                        }}
                                                        placeholder={`${isGerm ? DE.password : EN.password}*`} />
                                                    <div style={{ position: "absolute", marginRight: 10 }}>
                                                        {!viewPassword && (
                                                            <BsFillEyeSlashFill
                                                                className="pointer"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setViewPassword(true);
                                                                }}
                                                            />
                                                        )}
                                                        {viewPassword && (
                                                            <BsFillEyeFill
                                                                className="pointer"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setViewPassword(false);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            {/* {errors.password && (
                                                <small className="pl-2 text-danger">
                                                    {errors.password}
                                                </small>
                                            )} */}
                                            <Form.Group controlId="formBasicPassword">
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                                    <Form.Control
                                                        autoComplete="off"
                                                        type={viewPasswordConfirm ? "text" : "password"}
                                                        value={passwordParam.confirmPassword}
                                                        onChange={(e) => {
                                                            setPasswordParams('confirmPassword', e.target.value)
                                                        }}
                                                        placeholder={`${isGerm ? DE.confirm_password : EN.confirm_password}*`} />
                                                    <div style={{ position: "absolute", marginRight: 10 }}>
                                                        {!viewPasswordConfirm && (
                                                            <BsFillEyeSlashFill
                                                                className="pointer"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setViewPasswordConfirm(true);
                                                                }}
                                                            />
                                                        )}
                                                        {viewPasswordConfirm && (
                                                            <BsFillEyeFill
                                                                className="pointer"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setViewPasswordConfirm(false);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            <p style={{ fontSize: "12px", marginLeft: 5, fontStyle: "italic", color: "gray" }}>{isGerm ? DE.pass_minimum.replace("**", "*") : EN.pass_minimum.replace("**", "*")}</p>
                                            <Button
                                                className="btn request-btn"
                                                onClick={resetPassword}>{isGerm ? DE.update : EN.update}</Button>
                                        </Form>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            {user?.username &&
                                <Card>
                                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="2"
                                        onClick={e => handleClassX('2')}
                                    >
                                        {isGerm ? DE.change_username : EN.change_username}
                                        {
                                            (displayU === '2') &&
                                            <BsChevronUp className="acr_up" />
                                        }
                                        {
                                            (displayU !== '2') &&
                                            <BsChevronDown className="acr_down" />
                                        }
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            <Form className="add_company_from">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Control autoComplete="off" type="text"
                                                        value={username}
                                                        onChange={(event) => {
                                                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                            // If the entered text matches the email regex, prevent further input
                                                            if (emailRegex.test(event.target.value)) {
                                                                // Optionally, you can provide feedback to the user that email address is not allowed
                                                                setErrors({ ...errors, username: isGerm ? DE.prevent_email : EN.prevent_email });
                                                                event.preventDefault();
                                                            }
                                                            else {
                                                                setErrors({ ...errors, username: null });
                                                                setusername(event.target.value)
                                                            }
                                                        }}
                                                        placeholder={isGerm ? DE.username : EN.username} />
                                                </Form.Group>
                                                {errors.username && (
                                                    <small className="pl-2 text-danger">{errors.username}</small>
                                                )}
                                                {!errors.username ?
                                                    <Button
                                                        className="btn request-btn"
                                                        onClick={resetUserName}>{isGerm ? DE.update : EN.update}</Button> : <></>}
                                            </Form>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            }
                            {user?.username ?
                                <Card>
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        // variant="link"
                                        // eventKey="1"
                                        onClick={e => {
                                            history.push({ pathname: '/security-pin-main', state: { isResetPIN: true } })
                                        }}
                                    >{isGerm ? DE.resetPIN : EN.resetPIN}
                                        <BsChevronRight className="acr_right" />
                                    </Accordion.Toggle>
                                </Card>
                                :
                                <></>
                            }

                            <Card ref={cardRef}>
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="6"
                                    onClick={e => handleClass('6')}>{isGerm ? DE.add_update_email : EN.add_update_email}
                                    {
                                        (display === '6') &&
                                        <BsChevronUp className="acr_up" />
                                    }
                                    {
                                        (display !== '6') &&
                                        <BsChevronDown className="acr_down" />
                                    }
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="6" in={display === '6'}>
                                    <Card.Body>
                                        <Form className="add_company_from">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control onChange={e => setEmail(e.target.value)} type="text"
                                                    placeholder={isGerm ? DE.enter_your_email_add : EN.enter_your_email_add} />
                                            </Form.Group>
                                            <Button className="btn request-btn"
                                                onClick={resetEmail}>{isGerm ? DE.update : EN.update}</Button>
                                        </Form>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>

                                <Card.Body>
                                    <div

                                        onClick={() => {
                                            if (!user?.emailVerified) {
                                                $crud.confirm({
                                                    title: "",
                                                    textContent: isGerm ? DE.text_to_verifiy : EN.text_to_verifiy,
                                                    options: {
                                                        ok: isGerm ? DE.ok : EN.ok,
                                                        cancel: "",
                                                    },
                                                });
                                            }
                                        }}
                                        style={{ display: "flex", flex: 1, alignItems: 'center' }} className="project-checkbox">
                                        <div style={{ flex: 1, display: 'flex', alignItems: "center" }}>
                                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                                <div style={{ display: 'flex' }}>
                                                    <span style={{ fontWeight: '600', color: !user?.emailVerified ? "#DFDFDF" : "#000" }}>
                                                        {isGerm ? DE.email_on : EN.email_on}
                                                    </span>
                                                    <a style={{ marginLeft: 5 }} href="#"
                                                        onClick={(e) => {
                                                            $crud.confirm({
                                                                title: "",
                                                                textContent: isGerm ? DE.info_val : EN.info_val,
                                                                options: {
                                                                    ok: isGerm ? DE.ok : EN.ok,
                                                                    cancel: "",
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        <img
                                                            style={{ top: 10, position: 'absolute', bottom: 0 }}
                                                            width={17}
                                                            src={
                                                                !userN.emailVerified ? process.env.PUBLIC_URL + "/assets/img/info-icon-disabled.svg" : process.env.PUBLIC_URL + "/assets/img/info-icon.svg"
                                                            }
                                                        />
                                                    </a>
                                                </div>
                                                {(user?.email) ?
                                                    user?.emailVerified ?
                                                        <span style={{ fontSize: "12px", color: !user?.emailVerified ? "#DFDFDF" : "#000", fontStyle: "italic", marginTop: 3, fontWeight: "600", }}>
                                                            <span style={{ color: !user?.emailVerified ? "#DFDFDF" : "#000", fontWeight: "600" }}>{isGerm ? DE.stored_email : EN.stored_email}</span>  {user?.email}
                                                        </span>
                                                        :
                                                        <span style={{ fontSize: "12px", color: !user?.emailVerified ? "#DFDFDF" : "#000", fontStyle: "italic", marginTop: 3, fontWeight: "600", }}>
                                                            <span style={{ color: "#DFDFDF", fontWeight: "600" }}>{isGerm ? DE.stored_email : EN.stored_email} {isGerm ? DE.verify_email : EN.verify_email}</span>
                                                        </span>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </div>
                                        <Form>
                                            <Form.Check
                                                checked={isEmailOn}
                                                onChange={() => { _onPreEmailNotification() }}
                                                type="switch"
                                                id="custom-switchx-setting-user"
                                                style={{ marginTop: 2 }}
                                                disabled={!user?.emailVerified}
                                            />
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>
                                    {isGerm ? DE.delete_profile : EN.delete_profile} <BsX
                                        onClick={e => deleteProfile()} className="close_icon pointer" />
                                </Card.Header>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Button onClick={logout} variant="link"
                                        className="setting-logout-btn"> {isGerm ? DE.logout : EN.logout}</Button>
                                </Card.Header>
                            </Card>


                        </Accordion>
                    </div>
                    <p style={{ marginLeft: 10, marginTop: 10, fontWeight: "600", color: KJUUP_COLORS.base_color }}><span style={{ color: "grey" }}>Version</span> : {version}</p>
                </div>
            </Layout>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    const { language } = state;
    return {
        language: language,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        toggleLanguage: value => dispatch({ type: LANGUAGE, language: value }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileSetting));

